import { dateToStringFormat } from "./datesHelper";

export const appMockedStore = {
  auth: {
    isLogin: true,
    isLoading: { metadata: false, segmentation: false },
    isDownloading: { update: false },
    refresh_token_expires_in: new Date() + 1,
    userId: "abc123"
  },
  dicomFiles: { "path/to/file": { error: "error", file: "file" } },
  selectedDicom: {
    selectedFile: null,
    selectedPatient: null,
    selectedStudy: null,
    selectedSerie: null
  },
  segmentation: {
    "76a767fd-757e-43d3-94e2-a1736c972d96": {
      id: "76a767fd-757e-43d3-94e2-a1736c972d96",
      created: "2019-11-05T09:21:25.738240",
      status: "FINISHED",
      input: {
        path: "C:\\Users\\User-PC\\Documents\\DICOMDIR",
        patient: "3",
        study: "9999.82118032130525556189739282678451192521",
        series: "9999.292578481311132748547228841723593987421"
      },
      patientData: {
        selectedPatientData: {
          name: "Test name"
        },
        selectedSerieData: {
          number: 10,
          description: "Series description"
        }
      },
      savingCaseStatus: "SUCCESS"
    }
  },
  sidebarHighlightedItem: { currentLocation: "/" },
  caseList: {
    plannedCasesYears: [],
    casesByYear: [],
    status: null,
    selectedFilter: "",
    selectedDateFilter: null,
    cases: {},
    casesStateCount: {
      planned: 32,
      unplanned: 773,
      surgeryDay: 1,
      postOp: 199
    },
    caseStateCountError: false,
    casesByDayPerWeek: [
      {
        key: dateToStringFormat(new Date().setDate(new Date().getDate() - 17)),
        count: 1
      },
      {
        key: dateToStringFormat(new Date().setDate(new Date().getDate() - 16)),
        count: 1
      },
      {
        key: dateToStringFormat(new Date().setDate(new Date().getDate() - 15)),
        count: 1
      },
      {
        key: dateToStringFormat(new Date().setDate(new Date().getDate() - 14)),
        count: 1
      },
      {
        key: dateToStringFormat(new Date().setDate(new Date().getDate() - 13)),
        count: 1
      },
      {
        key: dateToStringFormat(new Date().setDate(new Date().getDate() - 12)),
        count: 1
      },
      {
        key: dateToStringFormat(new Date().setDate(new Date().getDate() - 11)),
        count: 1
      },
      {
        key: dateToStringFormat(new Date().setDate(new Date().getDate() - 10)),
        count: 1
      },
      {
        key: dateToStringFormat(new Date().setDate(new Date().getDate() - 9)),
        count: 1
      },
      {
        key: dateToStringFormat(new Date().setDate(new Date().getDate() - 8)),
        count: 1
      },
      {
        key: dateToStringFormat(new Date().setDate(new Date().getDate() - 7)),
        count: 1
      },
      {
        key: dateToStringFormat(new Date().setDate(new Date().getDate() - 6)),
        count: 1
      },
      {
        key: dateToStringFormat(new Date().setDate(new Date().getDate() - 5)),
        count: 1
      },
      {
        key: dateToStringFormat(new Date().setDate(new Date().getDate() - 4)),
        count: 1
      },
      {
        key: dateToStringFormat(new Date().setDate(new Date().getDate() - 3)),
        count: 1
      },
      {
        key: dateToStringFormat(new Date().setDate(new Date().getDate() - 2)),
        count: 1
      },
      {
        key: dateToStringFormat(new Date().setDate(new Date().getDate() - 1)),
        count: 1
      }
    ],
    casesByDayPerWeekStatus: "SUCCESS",
    caseListPopOverStatus: null,
    caseListPopOver: []
  },
  surgeryCase: { casePlanningStatus: null },
  applicationSegmentationUpload: { casesStatus: {} },
  archiveCase: { archiveCasesStatus: {} },
  requestSupport: {
    dicomUploadTasks: {
      [123]: {
        status: "LOADING",
        progress: 80,
        patientName: "Patient Name",
        error: "error"
      }
    }
  }
};

export const DicomDirectoryTreeMockedStore = {
  auth: {
    isLogin: true
  },
  dicomFiles: {
    "/Users/nicoramirez/Documents/GSI RESOURCES/CT_Disc2_20190912": {
      0: {
        name: "047 PAT",
        id: "074489",
        birthdate: "1946-04-14",
        gender: "U",
        comments: "",
        _id: 0,
        Studies: {
          study1: {
            id: "",
            accessionNumber: "3081188614376110",
            studyDate: "2017-11-05",
            studyTime: "",
            perfPhysicianName: "",
            description: "CT SHOULDER",
            _id: "9999.57280495732235825128012555912456223327",
            Series: {
              serie1_1: {
                number: 205,
                modality: "NOT CT",
                isOrientationSupported: true,
                laterality: "",
                contrastAgent: "",
                description: "SHD SCAP LT OMAR, iDose (1)",
                relativePath: "STU00007",
                imageType: ["ORIGINAL", "PRIMARY", "AXIAL"],
                _id: "9999.111499149925233405398171142488513858195"
              }
            }
          }
        }
      },
      1: {
        name: "043 PAT",
        id: "239220",
        birthdate: "1942-05-09",
        gender: "U",
        comments: "",
        _id: 1,
        Studies: {
          study2: {
            id: "",
            accessionNumber: "1951107529401425",
            studyDate: "2012-06-30",
            studyTime: "",
            perfPhysicianName: "",
            description: "CT UPPER EXT RIGHT W/O CONTRAST",
            _id: "9999.112541188771412707937176613721438309944",
            Series: {
              serie2_1: {
                number: 2,
                modality: "CT",
                isOrientationSupported: true,
                laterality: "",
                contrastAgent: "",
                description: "Blueprint axial bone window",
                relativePath: "STU00000",
                imageType: ["DERIVED", "PRIMARY", "AXIAL", "CT_SOM5 SPI"],
                _id: "9999.31809046861734176831944160742686400024"
              }
            }
          }
        }
      }
    }
  },
  selectedDicom: {
    selectedFile:
      "/Users/nicoramirez/Documents/GSI RESOURCES/CT_Disc2_20190912",
    selectedPatient: "0",
    selectedStudy: "9999.57280495732235825128012555912456223327",
    selectedSerie: "9999.155498161976432559456761066610303757347"
  },
  segmentation: {},
  surgeryCase: {},
  sidebarHighlightedItem: {
    currentLocation: "#/"
  },
  caseList: {}
};

export const userPreferencesMockedStore = {
  userPreferences: {
    preferences: {
      planning: {
        glenosphere: false,
        movementControl: true,
        planes: false,
        zoom: 2,
        implant: true,
        scapula: true,
        coordinateAxes: false,
        centralScrew: false,
        rotationControl: true,
        humerus: false,
        cameraView: "Front",
        opacity: 2,
        backsideSupport: false,
        pocket: false
      },
      measurements: {
        humerus: false,
        cameraView: "Front",
        planes: true,
        zoom: 3,
        opacity: 1,
        scapula: true,
        coordinateAxes: true
      }
    }
  }
};

export const mockedProtocolValues = {
  protocolValues: {
    protocol_metadata: {
      sliceThickness: {
        value: 123,
        qualityTag: "H"
      },
      amountOfSlices: {
        value: 200,
        qualityTag: "NS"
      },
      imageSize: {},
      pixelSpacing: {},
      fileOfView: {},
      patientPosition: {},
      patientOrientation: {
        x: 12,
        y: 11.5
      },
      transferSyntaxUID: {},
      exposureTime: {},
      convolutionKernel: {},
      kvp: {}
    }
  }
};

export const mockedSurgeryMeasurements = {
  segmentationData: {
    sphereRadius: 0.2583514153957367,
    boundingBox: {
      LeftFrontTop: {
        x: 0.985986328125,
        y: -1.519091796875,
        z: 0.8970091247558594
      },
      RightFrontBottom: {
        x: -1.6536621093750001,
        y: -1.519091796875,
        z: -0.7710078430175782
      },
      LeftBackTop: {
        x: 0.985986328125,
        y: 1.120556640625,
        z: 0.8970091247558594
      },
      LeftBackBottom: {
        x: 0.985986328125,
        y: 1.120556640625,
        z: -0.7710078430175782
      },
      LeftFrontBottom: {
        x: 0.985986328125,
        y: -1.519091796875,
        z: -0.7710078430175782
      },
      RightBackBottom: {
        x: -1.6536621093750001,
        y: 1.120556640625,
        z: -0.7710078430175782
      },
      RightBackTop: {
        x: -1.6536621093750001,
        y: 1.120556640625,
        z: 0.8970091247558594
      },
      RightFrontTop: {
        x: -1.6536621093750001,
        y: -1.519091796875,
        z: 0.8970091247558594
      }
    },
    glenoidCenter: {
      x: 0.18688851806667398,
      y: -0.37458121990832016,
      z: 0.31515739562756395
    },
    protocolMetadata: {
      exposureTime: { expected: "1000 - 1500", qualityTag: "NC", value: 1000 },
      kvp: { expected: "120 - 140", qualityTag: "NC", value: 140 },
      fileOfView: { expected: "1 - 270", qualityTag: "H", value: 265 },
      pixelSpacing: { expected: ".51", qualityTag: "H", value: 0.517578125 },
      xRayTubeCurrent: { expected: 240, qualityTag: "NC", value: 240 },
      sliceThickness: { expected: ".625 - 1.250", qualityTag: "H", value: 1 },
      patientPosition: { expected: "HFS", qualityTag: "NC", value: "HFS" },
      convolutionKernel: {
        expected: "Bone or Bone+",
        qualityTag: "NC",
        value: "B60s"
      },
      hounsfieldValues: {
        min: -1024,
        max: 3072,
        expected: "-1024 - 3071",
        qualityTag: "NC"
      },
      imageSize: {
        expected: "512x512 or 768x768",
        qualityTag: "H",
        value: "512x512"
      },
      patientOrientation: {
        qualityTag: "H",
        x: 0,
        y: 0,
        expected_X: "0° - 30",
        expected_Y: "0° - 30"
      },
      amountOfSlices: { expected: "100 - 500", qualityTag: "H", value: 279 },
      transferSyntaxUID: {
        expected: "",
        qualityTag: "H",
        value: "1.2.840.10008.1.2.4.70"
      }
    },
    patientId: "0",
    inferiorTip: {
      x: -0.33642578125,
      y: 0.771191418170929,
      z: -0.5494959354400635
    },
    sphereCenter: {
      x: 0.3868641257286072,
      y: -0.6104891300201416,
      z: 0.3165624737739563
    },
    trigonum: {
      x: -0.500659167766571,
      y: 0.3519531190395355,
      z: 0.5130051970481873
    },
    studyId: "9999.248664481922352704255983434827155937602",
    bestFitSphereCenter: {
      x: 0.3725557109191925,
      y: -0.5650815337540276,
      z: 0.3251309671720135
    },
    seriesId: "9999.142248873137007682910093591924822751062"
  },
  patientProfileData: {
    HumerusSubluxation: 0.5003791451454163,
    GlenoidInclination: 6,
    GlenoidVersion: -11
  },
  lastUpdateDate: "2022-04-05T18:21:14.877637",
  action: null,
  surgeonData: {
    GlenoidCenter: {
      x: 0.18688847124576569,
      y: -0.37458157539367676,
      z: 0.31515729427337646
    },
    Trigonum: {
      x: -0.5006592273712158,
      y: 0.35195308923721313,
      z: 0.5130051374435425
    },
    BestFitSphereCenter: {
      x: 0.3968724012374878,
      y: -0.53846675157547,
      z: 0.3118988275527954
    },
    InferiorTip: {
      x: -0.3364260494709015,
      y: 0.7711915969848633,
      z: -0.5494961142539978
    }
  },
  id: 123456,
  state: "CORRECTED",
  type: "surgerymeasurements"
};

const mockedImplantData = {
  SuperiorDepth: 0.0002928839239757508,
  SecondaryVersion: 0,
  RimPartialSeating: 0.1875,
  PosteriorDepth: -0.10000000149011612,
  PositionOffset: {
    x: 0,
    y: 0,
    z: 0
  },
  SecondaryInclination: 0,
  InferiorDepth: 0.004136544186621904,
  Rotation: 0,
  Inclination: 0,
  Lateralization: -5.543552816789088e-8,
  Version: 0,
  Seating: 0.4874371886253357,
  RimFullSeating: 0.21875,
  AnatomicOptsData: {
    ImplantType: 0,
    Depth: 0,
    Diameter: 2
  },
  AnteriorDepth: -0.10000000149011612,
  ReverseOptsData: {
    GlenosphereType: 0,
    CentralScrew: 0,
    GlenosphereDiameter: 0,
    ImplantModel: 0,
    BaseplateDiameter: 0,
    BaseplateStem: 0,
    BaseplateType: 0,
    GlenosphereOffset: 1,
    BaseplateAngle: 0,
    GlenosphereRotation: 0
  }
};

const mockedCTScansSettingsData = {
  SelectedGradient: 0,
  OneHLStop1: 0.31103515625,
  TwoHLStop2: 0.4111328125,
  ImplantOpacity: 0.5,
  Brightness: 0,
  TwoHLStop3: 0.73828125,
  OneHLStop2: 0.4111328125,
  IsUsingColorBlindedFriendlyGradients: false,
  TwoHLStop1: 0.31103515625,
  Contrast: 0
};

export const planningMockedData = [
  {
    data: {
      PlanData: {
        name: "Plan 1",
        idx: 0,
        isFavorite: false
      },
      ImplantData: { ...mockedImplantData },
      apiVersion: "1.1",
      CustomMeasurements: [],
      CustomMeasurePoints: [],
      ImplantName: "0S0CI24006___Glenoid_Circular_In_Line_Peg_24mm_x_6mm_RevA",
      CTScanSettingsData: { ...mockedCTScansSettingsData },
      Procedure: 0
    },
    lastUpdateDate: "2022-04-07T14:22:54.286464",
    lastModifiedPlanningDate: "2022-04-07T14:22:54.283292Z",
    action: null,
    id: 123456,
    type: "surgeryplanning"
  },
  {
    data: {
      PlanData: {
        name: "Plan 2",
        idx: 1,
        isFavorite: false
      },
      ImplantData: { ...mockedImplantData },
      apiVersion: "1.1",
      CustomMeasurements: [],
      CustomMeasurePoints: [],
      ImplantName: "0S0CI24006___Glenoid_Circular_In_Line_Peg_24mm_x_6mm_RevA",
      CTScanSettingsData: { ...mockedCTScansSettingsData },
      Procedure: 0
    },
    lastUpdateDate: "2022-04-07T14:22:54.541279",
    lastModifiedPlanningDate: "2022-04-07T14:22:54.541035Z",
    action: null,
    id: 123456,
    type: "surgeryplanning"
  }
];

export const TEST_TOKEN =
  "eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJzUGtfY1BOejV6Z0c4Tk1ibEg3R19MSFprQm9Ja0haSTc2SGtJZkxRUVh3In0.eyJleHAiOjE2MzE3OTM3MjAsImlhdCI6MTYzMTc5MzY2MCwiYXV0aF90aW1lIjoxNjMxNzkzNjU5LCJqdGkiOiJlYWQ3MTkwYS01YWJmLTQzNjQtYTU0Yy0yNGYzYzg3OWQ0MWQiLCJpc3MiOiJodHRwczovL3Nzby5kZXYuZ2VuZXNpc3BsYW5uaW5nc29mdHdhcmUuY29tL2F1dGgvcmVhbG1zL21hc3RlciIsImF1ZCI6WyJtYXN0ZXItcmVhbG0iLCJhY2NvdW50Il0sInN1YiI6IjZlNmM1MmZmLTY5ZjMtNGJmYy04N2M0LTU0YzBhMzMxMzM2MyIsInR5cCI6IkJlYXJlciIsImF6cCI6InNpLWJhY2tvZmZpY2UtdGVzdCIsInNlc3Npb25fc3RhdGUiOiI1MDU5ODM3Mi0wYjhlLTQ0NWEtODkxNy0yZTUzMzA2MmU4ODkiLCJhY3IiOiIxIiwiYWxsb3dlZC1vcmlnaW5zIjpbIioiXSwicmVhbG1fYWNjZXNzIjp7InJvbGVzIjpbImNyZWF0ZS1yZWFsbSIsImRldiIsIm9mZmxpbmVfYWNjZXNzIiwic3VyZ2VvbiIsImFkbWluIiwidW1hX2F1dGhvcml6YXRpb24iXX0sInJlc291cmNlX2FjY2VzcyI6eyJtYXN0ZXItcmVhbG0iOnsicm9sZXMiOlsidmlldy1pZGVudGl0eS1wcm92aWRlcnMiLCJ2aWV3LXJlYWxtIiwibWFuYWdlLWlkZW50aXR5LXByb3ZpZGVycyIsImltcGVyc29uYXRpb24iLCJjcmVhdGUtY2xpZW50IiwibWFuYWdlLXVzZXJzIiwicXVlcnktcmVhbG1zIiwidmlldy1hdXRob3JpemF0aW9uIiwicXVlcnktY2xpZW50cyIsInF1ZXJ5LXVzZXJzIiwibWFuYWdlLWV2ZW50cyIsIm1hbmFnZS1yZWFsbSIsInZpZXctZXZlbnRzIiwidmlldy11c2VycyIsInZpZXctY2xpZW50cyIsIm1hbmFnZS1hdXRob3JpemF0aW9uIiwibWFuYWdlLWNsaWVudHMiLCJxdWVyeS1ncm91cHMiXX0sImFjY291bnQiOnsicm9sZXMiOlsibWFuYWdlLWFjY291bnQiLCJtYW5hZ2UtYWNjb3VudC1saW5rcyIsInZpZXctcHJvZmlsZSJdfX0sInNjb3BlIjoicHJvZmlsZSBlbWFpbCIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJuYW1lIjoiTHVjaWFubyBCZXJ0ZWxsbyIsInByZWZlcnJlZF91c2VybmFtZSI6ImxiZXJ0ZWxsbyIsImdpdmVuX25hbWUiOiJMdWNpYW5vIiwiZmFtaWx5X25hbWUiOiJCZXJ0ZWxsbyIsImVtYWlsIjoibGJlcnRlbGxvQHNpbXRsaXguY29tIn0.kxv05ufWSGHjC78_cHacHLcXE0iATg5fuiY8Uj0pzA4aRGTgY58HQo-Wf6QYmfMD7C1H5gNq-LuwFqUMjLCRsD6PIEszT-Srdd6LPjXhWlC__9gPiMTKVUV9pi-ZL8ELpIR6GaXtln_YcvYgaFZPfeaIolYrgGzOkxzrpuPXS1I6z0XYiYcwnM16OV34dC5ERGbqGPkXS-SmwmIDzYwMTuHZr7CjMFbl63d7ZZjDAVWMLMS2sKFcBWoXep5mDSfGK7wiRnadoy9PahwgEmOifr_5wruuNm69BgwXrQX59FqjQrJuW8zpkfKs7oQWscqTI2epgKoUU5XEuPlIZtw7yw";

export const mockedEnvVariables = {
  REACT_APP_KEYCLOAK_URL: "https://sso.dev.genesisplanningsoftware.com/auth/",
  REACT_APP_KEYCLOAK_REALM: "master",
  REACT_APP_PORTAL_CLIENT_ID: "gsi-portal-shell",
  REACT_APP_PORTAL_URL: "https://si-portal.dev.genesisplanningsoftware.com/",
  REACT_APP_SSO_AUTHENTICATOR_URL: "http://localhost:9001/auth",
  REACT_APP_SURGERY_CORE_URL:
    "https://surgery-core.dev.genesisplanningsoftware.com",
  REACT_APP_JENKINS_URL: "https://tools.ci.genesisplanningsoftware.com/jenkins",
  REACT_APP_SUPPORT_JOB_ENDPOINT: "job/Preview-Shoulder-Support_Dev/job",
  REACT_APP_PASSWORD_RESET_TIMEOUT: 86400
};
