import React from "react";

import { Spin, Icon } from "antd";
import "./BaseSpinner.less";

const antIcon = <Icon type="loading" style={{ fontSize: "1.5rem" }} spin />;

const BaseSpinner = () => {
  return (
    <div className="base-spinner">
      <Spin indicator={antIcon} />
    </div>
  );
};

export default React.memo(BaseSpinner);
