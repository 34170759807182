import React from "react";
import PropTypes from "prop-types";
import "./InputCell.less";
import BaseCheckBox from "../baseComponents/BaseCheckBox";
import BaseTextArea from "../baseComponents/BaseTextArea";
import BaseTextInput from "../baseComponents/BaseTextInput";
import { inputType } from "../baseComponents/baseComponentTypes";

const InputCell = ({
  label = "",
  config = null,
  type = "",
  onChange = null,
  onPressEnter = null,
  className = "",
  childClassName = "",
  showError = ""
}) => {
  const inputComponent = () => {
    switch (type) {
      case inputType.TEXTINPUT:
        return (
          <BaseTextInput
            config={config}
            onChange={onChange}
            onPressEnter={onPressEnter}
            childClassName={childClassName}
          />
        );
      case inputType.CHECKBOX:
        return <BaseCheckBox config={config} onChange={onChange} />;
      case inputType.TEXTAREA:
        return <BaseTextArea config={config} onChange={onChange} />;
      default:
        return null;
    }
  };

  return (
    <div className={"inputcell-container" + (className ? ` ${className}` : "")}>
      {label ? <div className="inputcell-label">{label}</div> : null}
      <div className="inputcell-value">
        {inputComponent()}
        {showError ? <span className="input-error">{showError}</span> : null}
      </div>
    </div>
  );
};
InputCell.propTypes = {
  label: PropTypes.string,
  config: PropTypes.object,
  type: PropTypes.string,
  onChange: PropTypes.func,
  onPressEnter: PropTypes.func,
  className: PropTypes.string,
  childClassName: PropTypes.string,
  showError: PropTypes.string
};
export default InputCell;
