export const inputType = {
  CHECKBOX: "checkbox",
  DATEPICKER: "datepicker",
  TEXTINPUT: "textinput",
  TEXTAREA: "textarea",
  RADIO: "radiobutton",
  SELECT: "select"
};

export const buttonType = {
  PRIMARY: "primary",
  DEFAULT: "default",
  LINK: "link"
};

export const buttonSize = {
  LARGE: "large",
  DEFAULT: "default",
  SMALL: "small"
};
export const buttonShape = {
  CIRCLE: "circle",
  ROUND: "round"
};

export const buttonConsts = {
  TYPE: buttonType,
  SIZE: buttonSize,
  SHAPE: buttonShape
};
