import axios from "axios";
import userService from "./userService";
import { addNewCaseFilesToBody } from "./common";
import { CASE_TASKS_PIPELINES, PAGINATION_SIZE } from "./consts";
import {
  sortByFactory,
  paramFactory,
  pathFactory,
  urlWithParamsFactory
} from "../library/url";

const KEYCLOACK_RESOURCE = {
  REALMS: "realms",
  AUTH: "auth",
  ADMIN: "admin",
  REALM: "realm",
  USERS: "users",
  COUNT: "count",
  ROLES: "roles",
  ACCOUNT: "account",
  ROLE_MAPPINGS: "role-mappings",
  SEND_EMAIL: "execute-actions-email",
  LIFE_SPAN: "lifespan",
  BACKOFFICE: "backoffice",
  REFRESH: "refresh",
  LOGOUT: "logout",
  PARAM_EMAIL_REDIRECT_URI: "redirect_uri",
  PARAM_EMAIL_CLIENT_ID: "client_id",
  CURRENT_SESSION: "sessions?current=true"
};

const API_RESOURCE = {
  SERVICES: "services",
  ENTITIES: "entities",
  CASES: "surgerycase",
  SUPPORT: "support",
  TICKETS: "case-ticket",
  TASKS: "tasks",
  CLONE_CASE: "clone-case",
  PLANNING: "surgeryplanning",
  PARAM_PAGE_SIZE: "pageSize",
  PARAM_SORT_BY: "sortBy",
  PARAM_SORT_BY_ORDER_DESC: "desc",
  PARAM_SORT_BY_ORDER_ASC: "asc",
  PARAM_SORT_BY_CREATED_DATE: "createdDate",
  PARAM_SORT_BY_ID: "id",
  PARAM_EXPAND: "expandChildren"
};

const JENKINS_RESOURCE = {
  JENKINS_BUILD: "buildWithParameters?delay=0sec",
  BUILD: "build"
};

export const BODY_PARSER = {
  UNDEFINED: "UNDEFINED",
  JSON: "JSON",
  QUERY_STRING: "QUERY_STRING"
};

export const ITEMS_COUNT_PARAM = "?_count";
export const EXPAND_PARAM = "expandChildren=true";
export const START_INDEX_PARAM = "?startIndex=";
export const ISSUE_ID_PARAM = "issueId";
export const PAGE_SIZE_PARAM = "&pageSize=";
export const JSON_CONTENT_TYPE = "application/json";
export const MULTIPART_FORM_DATA = "multipart/form-data";
export const FORM_ENCODED_CONTENT_TYPE = "application/x-www-form-urlencoded";

const getKeycloakBaseRealmsUrl = () => {
  return `${process.env.REACT_APP_KEYCLOAK_URL}${pathFactory(
    KEYCLOACK_RESOURCE.REALMS
  )}`;
};

const getKeycloakUrl = () => {
  return pathFactory(
    getKeycloakBaseRealmsUrl(),
    process.env.REACT_APP_KEYCLOAK_REALM
  );
};

const getKeycloakAdminUrl = () => {
  return `${process.env.REACT_APP_KEYCLOAK_URL}${pathFactory(
    KEYCLOACK_RESOURCE.ADMIN,
    KEYCLOACK_RESOURCE.REALMS,
    process.env.REACT_APP_KEYCLOAK_REALM
  )}`;
};

export const getBoBackendUrl = () => {
  return pathFactory(
    process.env.REACT_APP_SSO_AUTHENTICATOR_URL,
    KEYCLOACK_RESOURCE.BACKOFFICE
  );
};

export const getBoRefreshTokenUrl = () => {
  return pathFactory(getBoBackendUrl(), KEYCLOACK_RESOURCE.REFRESH);
};

export const getBoBackendLogoutUrl = () => {
  return pathFactory(getBoBackendUrl(), KEYCLOACK_RESOURCE.LOGOUT);
};

export const getSessionsUrl = () => {
  return pathFactory(
    getKeycloakUrl(),
    KEYCLOACK_RESOURCE.ACCOUNT,
    KEYCLOACK_RESOURCE.CURRENT_SESSION
  );
};

export const getUsersUrl = () => {
  return pathFactory(getKeycloakAdminUrl(), KEYCLOACK_RESOURCE.USERS);
};

export const getUserListUrl = (role, userCount = null) => {
  const params = [{ param: "first", value: 0 }];

  if (userCount) {
    params.push({ param: "max", value: userCount });
  }

  const paramString = paramFactory(params);

  return urlWithParamsFactory(
    pathFactory(getRolesUrl(role), KEYCLOACK_RESOURCE.USERS),
    paramString
  );
};

export const getUserCountUrl = () => {
  return pathFactory(getUsersUrl(), KEYCLOACK_RESOURCE.COUNT);
};

export const getRolesUrl = (roleName = null) => {
  const rolesUrl = pathFactory(getKeycloakAdminUrl(), KEYCLOACK_RESOURCE.ROLES);
  if (roleName) {
    return rolesUrl.concat(`/${roleName}`);
  }
  return rolesUrl;
};

export const getRoleMappingsUrl = userId => {
  return pathFactory(
    getUsersUrl(),
    userId,
    KEYCLOACK_RESOURCE.ROLE_MAPPINGS,
    KEYCLOACK_RESOURCE.REALM
  );
};

export const getSendEmailUrl = userId => {
  const params = [
    {
      param: KEYCLOACK_RESOURCE.LIFE_SPAN,
      value: process.env.REACT_APP_PASSWORD_RESET_TIMEOUT ?? 259200
    },
    {
      param: KEYCLOACK_RESOURCE.PARAM_EMAIL_REDIRECT_URI,
      value: process.env.REACT_APP_PORTAL_URL
    },
    {
      param: KEYCLOACK_RESOURCE.PARAM_EMAIL_CLIENT_ID,
      value: process.env.REACT_APP_PORTAL_CLIENT_ID
    }
  ];
  const paramString = paramFactory(params);

  return urlWithParamsFactory(
    pathFactory(getUsersUrl(), userId, KEYCLOACK_RESOURCE.SEND_EMAIL),
    paramString
  );
};

export const getSupportTicketsUrl = link => {
  return pathFactory(
    process.env.REACT_APP_SURGERY_CORE_URL,
    link ? link : getTicketsEndpoint()
  );
};

export const getTicketsEndpoint = () => {
  const params = [
    {
      param: API_RESOURCE.PARAM_PAGE_SIZE,
      value: PAGINATION_SIZE
    }
  ];

  const url = pathFactory(
    API_RESOURCE.SERVICES,
    API_RESOURCE.SUPPORT,
    API_RESOURCE.TICKETS
  );

  return `${urlWithParamsFactory(
    url,
    paramFactory(params)
  )}${getTicketsDefaultSortBy()}`;
};

export const getTaskUrl = (taskId = null) => {
  const rolesUrl = pathFactory(
    process.env.REACT_APP_SURGERY_CORE_URL,
    API_RESOURCE.SERVICES,
    API_RESOURCE.SUPPORT,
    API_RESOURCE.TASKS
  );
  if (taskId) {
    return rolesUrl.concat(`/${taskId}`);
  }
  return rolesUrl;
};

export const getTasksUrlByIssueParam = issueId => {
  const url = pathFactory(
    process.env.REACT_APP_SURGERY_CORE_URL,
    API_RESOURCE.SERVICES,
    API_RESOURCE.SUPPORT,
    API_RESOURCE.TASKS
  );

  const params = [
    {
      param: ISSUE_ID_PARAM,
      value: issueId
    }
  ];

  return urlWithParamsFactory(url, paramFactory(params));
};

export const getTicketsDefaultSortBy = () => {
  const sortParams = {
    param: API_RESOURCE.PARAM_SORT_BY_CREATED_DATE,
    value: API_RESOURCE.PARAM_SORT_BY_ORDER_DESC
  };

  const params = [
    {
      param: API_RESOURCE.PARAM_SORT_BY,
      value: sortByFactory(sortParams)
    }
  ];
  return `&${paramFactory(params)}`;
};

export const getPipelineUrl = pipelineName => {
  return pathFactory(
    process.env.REACT_APP_JENKINS_URL,
    process.env.REACT_APP_SUPPORT_JOB_ENDPOINT,
    pipelineName
  );
};

export const getPipelineBuildUrl = pipelineName => {
  return pathFactory(
    getPipelineUrl(pipelineName),
    JENKINS_RESOURCE.JENKINS_BUILD
  );
};

export const getBuildUrl = url => {
  return pathFactory(url, JENKINS_RESOURCE.BUILD);
};

export const getCaseUrl = caseId => {
  const url = pathFactory(
    process.env.REACT_APP_SURGERY_CORE_URL,
    API_RESOURCE.SERVICES,
    API_RESOURCE.ENTITIES,
    API_RESOURCE.CASES,
    caseId
  );

  return urlWithParamsFactory(url, EXPAND_PARAM);
};

export const getCasesDefaultSortBy = () => {
  const sortParams = {
    param: API_RESOURCE.PARAM_SORT_BY_ID,
    value: API_RESOURCE.PARAM_SORT_BY_ORDER_DESC
  };

  const params = [
    {
      param: API_RESOURCE.PARAM_EXPAND,
      value: true
    },
    {
      param: API_RESOURCE.PARAM_SORT_BY,
      value: sortByFactory(sortParams)
    }
  ];
  return `${paramFactory(params)}`;
};

export const getCasesEndpoint = () => {
  const url = pathFactory(
    API_RESOURCE.SERVICES,
    API_RESOURCE.ENTITIES,
    API_RESOURCE.CASES
  );

  return urlWithParamsFactory(url, getCasesDefaultSortBy());
};

export const getSurgeryCaseUrl = link => {
  return pathFactory(
    process.env.REACT_APP_SURGERY_CORE_URL,
    link ? link : getCasesEndpoint()
  );
};

export const getCloneCaseUrl = () => {
  return pathFactory(
    process.env.REACT_APP_SURGERY_CORE_URL,
    API_RESOURCE.SERVICES,
    API_RESOURCE.ENTITIES,
    API_RESOURCE.CLONE_CASE
  );
};

export const getPlanningUrl = planningId => {
  return pathFactory(
    process.env.REACT_APP_SURGERY_CORE_URL,
    API_RESOURCE.SERVICES,
    API_RESOURCE.ENTITIES,
    API_RESOURCE.PLANNING,
    planningId
  );
};

export const refreshToken = async () => {
  if (userService.isTokenExpired()) {
    await userService.updateToken();
  }
};

const refreshAndRetrieveToken = async () => {
  await refreshToken();
  return userService.getToken();
};

export const sendJenkinsBuildJob = async (
  pipeline,
  body,
  files,
  jenkinsAuth
) => {
  const pipelineUrl = getPipelineBuildUrl(pipeline);
  const auth = Buffer.from(
    `${jenkinsAuth.username}:${jenkinsAuth.apiKey}`,
    "utf-8"
  ).toString("base64");

  let formData = new FormData();
  for (const key in body) {
    formData.append(key, body[key]);
  }
  if (pipeline === CASE_TASKS_PIPELINES.INJECT) {
    addNewCaseFilesToBody(files, formData);
  }

  return axios.post(pipelineUrl, formData, {
    headers: {
      "Content-Type": MULTIPART_FORM_DATA,
      Authorization: `Basic ${auth}`
    }
  });
};

export const sendGetRequest = async (url, body = null) => {
  await refreshToken();
  const baseHeaders = {
    Authorization: "Bearer ".concat(userService.getToken())
  };
  if (body) {
    return axios.get(url, body, {
      headers: baseHeaders
    });
  } else {
    return axios.get(url, {
      headers: baseHeaders
    });
  }
};

export const sendPostRequest = async (url, bodyParser, body, contentType) => {
  const token = await refreshAndRetrieveToken();
  switch (bodyParser) {
    case BODY_PARSER.JSON:
      return axios.post(url, JSON.stringify(body), {
        headers: {
          "Content-Type": contentType,
          Authorization: "Bearer ".concat(token)
        }
      });
    case BODY_PARSER.QUERY_STRING:
      return axios.post(url, new URLSearchParams(body), {
        headers: {
          "Content-Type": contentType,
          Authorization: "Bearer ".concat(token)
        }
      });
    case BODY_PARSER.UNDEFINED:
    default:
      return axios.post(url, body, {
        headers: {
          "Content-Type": contentType,
          Authorization: "Bearer ".concat(token)
        }
      });
  }
};

export const sendPutRequest = async (url, bodyParser, body, contentType) => {
  const token = await refreshAndRetrieveToken();
  switch (bodyParser) {
    case BODY_PARSER.JSON:
      return axios.put(url, JSON.stringify(body), {
        headers: {
          "Content-Type": contentType,
          Authorization: "Bearer ".concat(token)
        }
      });
    case BODY_PARSER.QUERY_STRING:
      return axios.put(url, new URLSearchParams(body), {
        headers: {
          "Content-Type": contentType,
          Authorization: "Bearer ".concat(token)
        }
      });
    case BODY_PARSER.UNDEFINED:
    default:
      return axios.put(url, body, {
        headers: {
          "Content-Type": contentType,
          Authorization: "Bearer ".concat(token)
        }
      });
  }
};

export const sendDeleteRequest = async (url, contentType, isLogin = false) => {
  const headers = {
    "Content-Type": contentType
  };
  if (!isLogin) {
    await refreshToken();
    headers.Authorization = "Bearer ".concat(userService.getToken());
  }
  return axios.delete(url, { headers });
};

export const sendPatchRequest = async (url, bodyParser, body, contentType) => {
  const token = await refreshAndRetrieveToken();
  switch (bodyParser) {
    case BODY_PARSER.JSON:
      return axios.patch(url, JSON.stringify(body), {
        headers: {
          "Content-Type": contentType,
          Authorization: "Bearer ".concat(token)
        }
      });
    case BODY_PARSER.QUERY_STRING:
      return axios.patch(url, new URLSearchParams(body), {
        headers: {
          "Content-Type": contentType,
          Authorization: "Bearer ".concat(token)
        }
      });
    case BODY_PARSER.UNDEFINED:
    default:
      return axios.patch(url, body, {
        headers: {
          "Content-Type": contentType,
          Authorization: "Bearer ".concat(token)
        }
      });
  }
};

export const backofficeSentryURL =
  "https://b30f656c58a14e2092c7d9ae5a666bb7@apm.genesisplanningsoftware.com/13";
