import React from "react";
import PropTypes from "prop-types";
import { Input } from "antd";
import "./BaseTextArea.less";

const { TextArea } = Input;

const BaseTextArea = ({ config = null, onChange = null }) => {
  return (
    <TextArea
      className="input-container"
      onChange={onChange}
      autoSize={{
        minRows: config ? config.minRows : 2,
        maxRows: config ? config.maxRows : 5
      }}
      placeholder={config ? config.placeholder : ""}
      maxLength={config && config.maxlength}
      value={config && config.value}
      disabled={config && config.disabled}
    />
  );
};
BaseTextArea.propTypes = {
  config: PropTypes.object,
  onChange: PropTypes.func
};

export default BaseTextArea;
