export const isEven = numberToCheck => {
  if (isNaN(numberToCheck)) {
    return null;
  }
  return 0 === numberToCheck % 2;
};

export const bankersRound = numberToRound => {
  if (isNaN(numberToRound)) {
    return null;
  }
  const r = Math.round(numberToRound);
  const numberToRoundSign = numberToRound > 0 ? numberToRound : -numberToRound;

  if (numberToRoundSign % 1 === 0.5) {
    if (isEven(r)) {
      return r;
    }
    return r - 1;
  }
  return r;
};

export const getQualityTag = qualityValue => {
  const qualityTags = {
    H: "High quality",
    M: "Medium quality",
    L: "Low quality",
    NS: "Not supported"
  };

  return qualityTags[qualityValue];
};
