import React from "react";
import PropTypes from "prop-types";
import "./PlanningResult.less";

const PlanningResult = ({
  planningResultInfo,
  hasSeparator = false,
  hasBasis = false,
  noPadding = false,
  isHeader = false
}) => {
  return (
    <div
      className={`planning-result-container${noPadding ? " no-padding" : ""}`}
    >
      {planningResultInfo.map((resultInfo, key) => (
        <React.Fragment key={key}>
          {resultInfo.title && (
            <div className="planning-result-title">{resultInfo.title}</div>
          )}
          <div className="planning-result">
            {resultInfo.values.map((resultInfoValue, resultKey) => (
              <div
                className={`planning-result-detail${hasBasis ? " basis" : ""}${
                  isHeader && resultKey === 1 ? " header-second-column" : ""
                }`}
                key={resultKey}
              >
                <div className={`value${isHeader ? " header" : ""}`}>
                  {resultInfoValue.value}
                </div>
                <div className="attribute">{resultInfoValue.attribute}</div>
              </div>
            ))}
          </div>
        </React.Fragment>
      ))}
      {hasSeparator && <hr className="planning-result-separator" />}
    </div>
  );
};

PlanningResult.propTypes = {
  planningResultInfo: PropTypes.array,
  hasSeparator: PropTypes.bool,
  hasBasis: PropTypes.bool,
  noPadding: PropTypes.bool,
  isHeader: PropTypes.bool
};

export default React.memo(PlanningResult);
