import React from "react";
import PropTypes from "prop-types";
import { Card } from "antd";
import BaseButton from "../common/baseComponents/BaseButton";
import "./InfoCard.less";

const InfoCard = ({
  title = "",
  titleStyle = {},
  titleLinks = [],
  children,
  expand = false,
  buttons = null,
  isLast = false,
  cardClass = false
}) => {
  const links = titleLinks.map(link => {
    if (link.isText) {
      return (
        <span key={link.linkText} className="link-span">
          {link.linkText}
        </span>
      );
    } else {
      return (
        <BaseButton
          key={link.linkText}
          config={{
            type: "link",
            size: "small",
            disabled: link.disabled
          }}
          onClick={link.callback}
          text={link.linkText}
        />
      );
    }
  });

  const header = (headerTitle, headerButtons, style) => {
    if (!headerButtons) {
      return headerTitle;
    } else {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={style}>{headerTitle}</div>
          <div className="buttons">{headerButtons}</div>
        </div>
      );
    }
  };

  const simpleCard = (
    <Card
      className={`info-card ${expand ? "info-card-expanded" : ""} ${
        isLast ? "info-card-last" : ""
      }`}
    >
      {children}
    </Card>
  );

  const fullCard = (
    <Card
      className={`info-card ${isLast ? "info-card-last" : ""} ${
        cardClass ? "card-class-custom" : ""
      }`}
      headStyle={{ fontWeight: "600" }}
      title={header(title, buttons, titleStyle)}
      extra={links}
    >
      {children}
    </Card>
  );

  if (title) {
    return fullCard;
  } else {
    return simpleCard;
  }
};
InfoCard.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  titleLinks: PropTypes.array,
  children: PropTypes.element,
  button: PropTypes.element,
  isLast: PropTypes.bool,
  cardClass: PropTypes.bool
};

export default InfoCard;
