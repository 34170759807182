import React from "react";
import PropTypes from "prop-types";
import { Checkbox } from "antd";

import "./BaseCheckBox.less";

const BaseCheckBox = ({ config = null, onChange = null }) => {
  return (
    <Checkbox
      className="input-container"
      onChange={onChange}
      checked={config ? config.checked : false}
    >
      {config ? config.label : ""}
    </Checkbox>
  );
};
BaseCheckBox.propTypes = {
  config: PropTypes.object,
  onChange: PropTypes.func
};

export default BaseCheckBox;
