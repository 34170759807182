import React from "react";
import { Icon, Tooltip } from "antd";
import PropTypes from "prop-types";

import "./WarningDisclaimer.less";

const WarningDisclaimer = ({
  title = "HIPAA",
  tooltip = "Caution: Entering PHI/PII here may put you at risk for HIPAA compliance."
}) => {
  return (
    <div className="warning-disclaimer-container">
      <Tooltip title={tooltip} placement="right">
        <Icon type="exclamation-circle" theme="filled" />
        <label className="warning-disclamer-label">{title}</label>
      </Tooltip>
    </div>
  );
};

WarningDisclaimer.propTypes = {
  title: PropTypes.string,
  tooltip: PropTypes.string
};

export default React.memo(WarningDisclaimer);
