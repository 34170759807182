import { GLENOSPHERE_TYPE, implantTypeConst } from "./consts";
import { bankersRound } from "./caseHelper";

const getFinalDepth = depth => {
  return depth === 0 ? 6 : 8;
};

const checkIsNumber = valueToCheck => {
  return typeof valueToCheck === "number";
};

export const parseToDecimals = (numberToFix, decimals = 2) => {
  return numberToFix.toFixed(decimals).replace(/\.00$/, "");
};

export const getGlenoidSize = (depth, diameter, type) => {
  const finalDepth = getFinalDepth(depth);

  const DIAMETER_VALUES = {
    0: 20,
    1: 22,
    2: 24,
    3: 27,
    4: 30
  };

  const finalDiameter = DIAMETER_VALUES[diameter];

  return type === 0 ? `${finalDiameter}x${finalDepth}` : finalDiameter;
};

export const getMedLat = medLatValue => {
  return parseToDecimals(medLatValue, 4) * 1000 >= 0
    ? "Medialization"
    : "Lateralization";
};

export const parseMedLat = medLatValueToParse => {
  return Math.abs(parseToDecimals(medLatValueToParse, 4) * 1000)
    .toFixed(1)
    .replace(/\.0$/, "");
};

export const parseHumerusSubluxation = humerusSubluxation => {
  return parseSubluxationCalc(humerusSubluxation);
};

export const parseSubluxationCalc = humerusSubluxation => {
  const isNumber = checkIsNumber(humerusSubluxation);
  return isNumber && humerusSubluxation > 0 && humerusSubluxation <= 1
    ? `${bankersRound(100 * humerusSubluxation)}%`
    : "-";
};

export const parseRotation = glenoidRotation => {
  let rotationDegrees = (glenoidRotation / 12) * 360;
  if (rotationDegrees > 180) {
    rotationDegrees = rotationDegrees - 360;
  }
  return bankersRound(rotationDegrees);
};

export const parseInclination = glenoidInclination => {
  const isBetweenOne = glenoidInclination > -1 && glenoidInclination < 1;
  if (isBetweenOne) {
    return "Inclination";
  }
  if (glenoidInclination >= 1) {
    return "Inferior";
  }
  return "Superior";
};

export const parseVersion = (version, operationSideVal) => {
  let res;
  if (version > -1 && version < 1) {
    res = "Version";
  } else {
    if (operationSideVal === "LEFT") {
      res = version >= 1 ? "Anteversion" : "Retroversion";
    } else {
      res = version >= 1 ? "Retroversion" : "Anteversion";
    }
  }

  return res;
};

export const parseRim = rimValueToParse => {
  return rimValueToParse !== undefined
    ? bankersRound(rimValueToParse * 360) + "°"
    : "-";
};

export const getNoRimInset = (rimFullSeating, rimPartialSeating) => {
  return rimFullSeating !== undefined && rimPartialSeating !== undefined
    ? bankersRound(
        360 -
          bankersRound(rimFullSeating * 360) -
          bankersRound(rimPartialSeating * 360)
      ) + "°"
    : "-";
};

export const parseSeating = seatingToParse => {
  return seatingToParse !== undefined
    ? bankersRound(seatingToParse * 100) + "%"
    : "-";
};

const getGlenoidVersion = (operationSideVal, glenoidVersion) => {
  if (operationSideVal === "RIGHT") {
    return glenoidVersion > 0 ? "Glenoid anteversion" : "Glenoid retroversion";
  }
  return glenoidVersion > 0 ? "Glenoid retroversion" : "Glenoid anteversion";
};

export const parseGlenoidVersion = (glenoidVersion, operationSideVal) => {
  let res = "-";

  if (operationSideVal) {
    if (glenoidVersion === 0) {
      res = "Glenoid version";
    } else {
      res = getGlenoidVersion(operationSideVal, glenoidVersion);
    }
  }

  return res;
};

export const getNoSeating = seating => {
  return seating !== undefined
    ? bankersRound(100 - bankersRound(seating * 100)) + "%"
    : "-";
};

export const getImplantType = surgeryPlanningObject => {
  return implantTypeConst["Anatomic"][
    surgeryPlanningObject["ImplantData"]["AnatomicOptsData"]["ImplantType"]
  ];
};

export const getBasePlateAngle = angle => {
  const parsedAngleValues = {
    0: "0",
    1: "5",
    2: "10",
    3: "15"
  };
  return parsedAngleValues[angle] || "-";
};

export const getBasePlateDiameter = diameter => {
  const parsedDiameterValues = {
    0: "24",
    1: "27"
  };
  return parsedDiameterValues[diameter] || "-";
};

export const getBasePlateStem = stem => {
  const parsedStemValues = {
    0: "15",
    1: "25",
    2: "10"
  };
  return parsedStemValues[stem] || "-";
};

export const parseCentralScrew = centralScrew => {
  const parsedCentralScrewValues = {
    0: "20",
    1: "25",
    2: "30",
    3: "35",
    4: "40",
    5: "15"
  };
  return parsedCentralScrewValues[centralScrew] || "-";
};

export const getGlenosphereDiameter = diameter => {
  const parsedGlenosphereDiameterValues = {
    0: "33",
    1: "36",
    2: "39",
    3: "42"
  };
  return parsedGlenosphereDiameterValues[diameter] || "-";
};

export const getGlenosphereLabel = (type = GLENOSPHERE_TYPE.STANDARD) => {
  return type === GLENOSPHERE_TYPE.STANDARD ? "Offset" : "Rotation";
};

export const getGlenosphereOffset = offset => {
  const parsedGlenosphereOffsetValues = {
    0: "0",
    1: "3",
    2: "6"
  };

  return parsedGlenosphereOffsetValues[offset] || "-";
};

export const getGlenosphereValue = (
  offset,
  rotation,
  type = GLENOSPHERE_TYPE.STANDARD
) => {
  const isStandard = type === GLENOSPHERE_TYPE.STANDARD;
  if (isStandard) {
    return `${getGlenosphereOffset(offset)}mm`;
  }
  if (checkIsNumber(rotation)) {
    return `${rotation}°`;
  }
  return "-";
};

export const parseGlenosphereType = (type = GLENOSPHERE_TYPE.STANDARD) => {
  const parsedGlenosphereTypeValues = {
    [GLENOSPHERE_TYPE.STANDARD]: "Standard",
    [GLENOSPHERE_TYPE.ECCENTRIC]: "Eccentric"
  };
  return parsedGlenosphereTypeValues[type] || "-";
};

export const showImplantType = parsedSurgeryPlanning => {
  return parsedSurgeryPlanning["Procedure"] === 0
    ? {
        value: `${getImplantType(parsedSurgeryPlanning)}`,
        attribute: "Implant"
      }
    : {};
};

export const getProcedureName = parsedSurgeryPlanning => {
  return parsedSurgeryPlanning["Procedure"] === 0 ? "Anatomic" : "Reverse";
};

export const getPocketDepth = depth => {
  return depth > 0 ? `${(Math.round(depth * 10000) / 10).toFixed(1)}mm` : "-";
};
