import React from "react";
import { Icon } from "antd";
import { ReactComponent as LaunchLogo } from "../../../resources/newcase/inset_implant.svg";
import "./PlanningNotAvailable.less";

const PlanningNotAvailable = () => {
  return (
    <div className="launch-component">
      <div className="card-content">
        <div className="expandable"></div>
        <LaunchLogo className="launch-logo" />
        <div className="request-support-label">
          <div>
            <Icon type="close-circle" theme="filled" />
            <div>Planning unavailable.</div>
          </div>
        </div>
        <div className="expandable"></div>
      </div>
    </div>
  );
};

export default PlanningNotAvailable;
