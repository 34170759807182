import React from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { Icon, Tabs } from "antd";
import PlanningInfoTab from "./planningComponents/PlanningInfoTab";
import { PlanningNotAvailable } from "./planningComponents";
import "./SurgeryPlanningInfo.less";

const { TabPane } = Tabs;

const SurgeryPlanningInfo = ({
  surgeryPlanning,
  surgeryMeasurements,
  operationSideValue,
  activeTab,
  setActiveTab
}) => {
  return !isEmpty(surgeryPlanning) &&
    !isEmpty(surgeryMeasurements.patientProfileData) ? (
    <div className="launch-component planning-info">
      <div className="card-content">
        <Tabs
          activeKey={activeTab}
          animated={false}
          onChange={key => setActiveTab(key)}
        >
          {Array.isArray(surgeryPlanning) &&
            surgeryPlanning.map((plan, index) => (
              <TabPane
                forceRender={true}
                tab={
                  <span>
                    {plan.data.PlanData.name}
                    {plan.data.PlanData.isFavorite && (
                      <Icon
                        type="star"
                        theme="filled"
                        className={
                          parseInt(activeTab) === index
                            ? "favorite-icon-active"
                            : "favorite-icon"
                        }
                      />
                    )}
                  </span>
                }
                key={index}
              >
                <PlanningInfoTab
                  surgeryPlanning={plan.data}
                  surgeryMeasurements={surgeryMeasurements}
                  operationSideValue={operationSideValue}
                  lastModifiedPlanningDate={plan.lastModifiedPlanningDate}
                />
              </TabPane>
            ))}
        </Tabs>
      </div>
    </div>
  ) : (
    <PlanningNotAvailable />
  );
};

SurgeryPlanningInfo.propTypes = {
  surgeryPlanning: PropTypes.array,
  surgeryMeasurements: PropTypes.object,
  operationSideValue: PropTypes.string,
  activeTab: PropTypes.string,
  setActiveTab: PropTypes.func
};

export default SurgeryPlanningInfo;
