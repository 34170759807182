import React from "react";
import PropTypes from "prop-types";
import { compact } from "lodash";
import PlanningResult from "./PlanningResult";
import { bankersRound } from "../../../helpers/caseHelper";
import {
  showImplantType,
  parseHumerusSubluxation,
  parseInclination,
  parseToDecimals,
  parseVersion,
  parseRotation,
  parseMedLat,
  parseGlenosphereType,
  parseCentralScrew,
  parseRim,
  parseSeating,
  parseGlenoidVersion,
  getProcedureName,
  getGlenoidSize,
  getMedLat,
  getBasePlateStem,
  getBasePlateDiameter,
  getBasePlateAngle,
  getGlenosphereDiameter,
  getGlenosphereValue,
  getGlenosphereLabel,
  getNoRimInset,
  getNoSeating
} from "../../../helpers/launchReconHelper";
import { parseIsoStringToDate } from "../../../helpers/datesHelper";
import { surgeryMeasurementsState } from "../../../helpers/consts";
import "./PlanningInfoTab.less";

const generatePlanningResultValue = (
  procedure,
  operationSideValue,
  surgeryPlanning
) => {
  const isReverse = procedure;

  return compact([
    isReverse
      ? null
      : {
          value: `${getGlenoidSize(
            surgeryPlanning["ImplantData"]["AnatomicOptsData"]["Depth"],
            surgeryPlanning["ImplantData"]["AnatomicOptsData"]["Diameter"],
            surgeryPlanning["ImplantData"]["AnatomicOptsData"]["ImplantType"]
          )}mm`,
          attribute: "Size"
        },
    {
      value: `${Math.abs(
        bankersRound(
          isReverse
            ? surgeryPlanning["ImplantData"]["SecondaryInclination"]
            : surgeryPlanning["ImplantData"]["Inclination"],
          "pocketInclination"
        )
      )}°`,
      attribute:
        "Pocket " +
        parseInclination(
          isReverse
            ? surgeryPlanning["ImplantData"]["SecondaryInclination"]
            : surgeryPlanning["ImplantData"]["Inclination"]
        )
    },
    {
      value: `${Math.abs(
        bankersRound(
          isReverse
            ? surgeryPlanning["ImplantData"]["SecondaryVersion"]
            : surgeryPlanning["ImplantData"]["Version"],
          "version"
        )
      )}°`,
      attribute:
        "Pocket " +
        parseVersion(
          bankersRound(
            isReverse
              ? surgeryPlanning["ImplantData"]["SecondaryVersion"]
              : surgeryPlanning["ImplantData"]["Version"]
          ),
          operationSideValue
        )
    },
    {
      value: `${parseRotation(
        surgeryPlanning["ImplantData"]["Rotation"],
        "rotation"
      )}°`,
      attribute: "Rotation"
    },
    isReverse
      ? null
      : {
          value: `${Math.abs(
            bankersRound(
              surgeryPlanning["ImplantData"]["SecondaryInclination"],
              "faceInclination"
            )
          )}°`,
          attribute:
            "Face " +
            parseInclination(
              surgeryPlanning["ImplantData"]["SecondaryInclination"],
              false
            )
        },
    isReverse
      ? null
      : {
          value: `${Math.abs(
            bankersRound(
              surgeryPlanning["ImplantData"]["SecondaryVersion"],
              "faceVersion"
            )
          )}°`,
          attribute:
            "Face " +
            parseVersion(
              bankersRound(surgeryPlanning["ImplantData"]["SecondaryVersion"]),
              operationSideValue,
              false
            )
        },
    isReverse
      ? {
          value: `${Math.abs(
            bankersRound(
              surgeryPlanning["ImplantData"]["Inclination"],
              "faceInclination"
            )
          )}°`,
          attribute:
            "Gleno " +
            parseInclination(
              surgeryPlanning["ImplantData"]["Inclination"],
              false
            )
        }
      : null,
    isReverse
      ? {
          value: `${Math.abs(
            bankersRound(
              surgeryPlanning["ImplantData"]["Version"],
              "faceVersion"
            )
          )}°`,
          attribute:
            "Gleno " +
            parseVersion(
              bankersRound(surgeryPlanning["ImplantData"]["Version"]),
              operationSideValue,
              false
            )
        }
      : null,
    {
      value: `${parseMedLat(
        surgeryPlanning["ImplantData"]["Lateralization"]
      )}mm`,
      attribute: getMedLat(surgeryPlanning["ImplantData"]["Lateralization"])
    }
  ]);
};

const PlanningInfoTab = ({
  surgeryPlanning,
  surgeryMeasurements,
  operationSideValue,
  lastModifiedPlanningDate
}) => {
  return (
    <React.Fragment>
      <PlanningResult
        hasSeparator
        planningResultInfo={[
          {
            values: [
              {
                value: surgeryPlanning["PlanData"]["name"],
                attribute: "Plan name"
              },
              {
                value: getProcedureName(surgeryPlanning),
                attribute: "Procedure"
              },
              showImplantType(surgeryPlanning)
            ]
          }
        ]}
      />
      <PlanningResult
        hasSeparator
        planningResultInfo={[
          {
            title: "Patient's anatomy",
            values: [
              {
                value:
                  surgeryMeasurements.state ===
                  surgeryMeasurementsState.ACCEPTED
                    ? "Automated"
                    : "Surgeon modified",
                attribute: "Measurements"
              },
              {
                value: `${Math.abs(
                  parseToDecimals(
                    surgeryMeasurements.patientProfileData.GlenoidInclination,
                    2,
                    "glenoidInclination"
                  )
                )}°`,
                attribute:
                  surgeryMeasurements.patientProfileData.GlenoidInclination >= 0
                    ? "Glenoid inc. sup"
                    : "Glenoid inc. inf"
              },
              {
                value: `${Math.abs(
                  parseToDecimals(
                    surgeryMeasurements.patientProfileData.GlenoidVersion,
                    2,
                    "glenoidVersion"
                  )
                )}°`,
                attribute: parseGlenoidVersion(
                  surgeryMeasurements.patientProfileData.GlenoidVersion,
                  operationSideValue
                )
              },
              {
                value: parseHumerusSubluxation(
                  surgeryMeasurements.patientProfileData.HumerusSubluxation
                ),
                attribute: "Subluxation"
              }
            ]
          }
        ]}
      />
      {surgeryPlanning["Procedure"] === 0 ? (
        <PlanningResult
          hasSeparator
          planningResultInfo={[
            {
              title: "Glenoid implant properties",
              values: generatePlanningResultValue(
                0,
                operationSideValue,
                surgeryPlanning
              )
            }
          ]}
        />
      ) : (
        <React.Fragment>
          <PlanningResult
            planningResultInfo={[
              {
                title: "Baseplate properties",
                values: [
                  {
                    value: `${getBasePlateDiameter(
                      surgeryPlanning["ImplantData"]["ReverseOptsData"][
                        "BaseplateDiameter"
                      ]
                    )}mm`,
                    attribute: "Diameter"
                  },
                  {
                    value: `${getBasePlateStem(
                      surgeryPlanning["ImplantData"]["ReverseOptsData"][
                        "BaseplateStem"
                      ]
                    )}mm`,
                    attribute: "Stem"
                  },
                  {
                    value: `${getBasePlateAngle(
                      surgeryPlanning["ImplantData"]["ReverseOptsData"][
                        "BaseplateAngle"
                      ]
                    )}°`,
                    attribute: "Angle"
                  }
                ]
              }
            ]}
          />
          <PlanningResult
            noPadding
            planningResultInfo={[
              {
                title: "6.0mm central screw",
                values: [
                  {
                    value: `${parseCentralScrew(
                      surgeryPlanning["ImplantData"]["ReverseOptsData"][
                        "CentralScrew"
                      ]
                    )}mm`,
                    attribute: "Length"
                  }
                ]
              }
            ]}
          />
          <PlanningResult
            hasSeparator
            noPadding
            planningResultInfo={[
              {
                title: "Glenosphere",
                values: [
                  {
                    value: `${parseGlenosphereType(
                      surgeryPlanning["ImplantData"]["ReverseOptsData"][
                        "GlenosphereType"
                      ]
                    )}`,
                    attribute: "Type"
                  },
                  {
                    value: `${getGlenosphereDiameter(
                      surgeryPlanning["ImplantData"]["ReverseOptsData"][
                        "GlenosphereDiameter"
                      ]
                    )}mm`,
                    attribute: "Diameter"
                  },
                  {
                    value: `${getGlenosphereValue(
                      surgeryPlanning["ImplantData"]["ReverseOptsData"][
                        "GlenosphereOffset"
                      ],
                      parseRotation(
                        surgeryPlanning["ImplantData"]["ReverseOptsData"][
                          "GlenosphereRotation"
                        ]
                      ),
                      surgeryPlanning["ImplantData"]["ReverseOptsData"][
                        "GlenosphereType"
                      ]
                    )}`,
                    attribute: `${getGlenosphereLabel(
                      surgeryPlanning["ImplantData"]["ReverseOptsData"][
                        "GlenosphereType"
                      ]
                    )}`
                  }
                ]
              }
            ]}
          />
          <PlanningResult
            hasSeparator
            planningResultInfo={[
              {
                title: "Surgery properties",
                values: generatePlanningResultValue(
                  1,
                  operationSideValue,
                  surgeryPlanning
                )
              }
            ]}
          />
        </React.Fragment>
      )}
      <PlanningResult
        planningResultInfo={[
          {
            title: "Implant support",
            values: [
              {
                value: `${parseRim(
                  surgeryPlanning["ImplantData"]["RimFullSeating"],
                  "rimMajor"
                )}`,
                attribute: "Rim >= 2mm"
              },
              {
                value: `${parseRim(
                  surgeryPlanning["ImplantData"]["RimPartialSeating"],
                  "rimMinor"
                )}`,
                attribute: "Rim 0.1 to 2mm"
              },
              {
                value: `${getNoRimInset(
                  surgeryPlanning["ImplantData"]["RimFullSeating"],
                  surgeryPlanning["ImplantData"]["RimPartialSeating"]
                )}`,
                attribute: "No Rim inset"
              },
              {
                value: `${getNoSeating(
                  surgeryPlanning["ImplantData"]["Seating"]
                )}`,
                attribute: "No seating"
              },
              {
                value: `${parseSeating(
                  surgeryPlanning["ImplantData"]["Seating"]
                )}`,
                attribute: "Backside support"
              },
              {}
            ]
          }
        ]}
      />
      {lastModifiedPlanningDate && (
        <div className="last-modified">
          {`Last modified 
          ${parseIsoStringToDate(lastModifiedPlanningDate)}`}
        </div>
      )}
    </React.Fragment>
  );
};

PlanningInfoTab.propTypes = {
  surgeryPlanning: PropTypes.object,
  surgeryMeasurements: PropTypes.object,
  operationSideValue: PropTypes.string,
  lastModifiedPlanningDate: PropTypes.string
};

export default PlanningInfoTab;
